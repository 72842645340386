import React, { useEffect, useState } from "react";

import "./App.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDMMiUZMYuHDfSKaY7cKSBzITGo3zUXUo4",
  authDomain: "patahi-dev.firebaseapp.com",
  projectId: "patahi-dev",
  storageBucket: "patahi-dev.appspot.com",
  messagingSenderId: "373604837531",
  appId: "1:373604837531:web:89a44a0a6f41fdb2d67e3e",
  measurementId: "G-YJJR8ZFJTF",
};

function App() {
  /*   const app = initializeApp(firebaseConfig);
  const ana = getFunctions(app);
  const getCartData = httpsCallable(ana, "getCartData"); */

  const [oneMessage, setOneMessage] = useState();
  const orders = [
    {
      id: "order1",
      items: [
        { name: "Item 1", price: 500.0, quantity: 1 },
        { name: "Item 2", price: 1000.0, quantity: 2 },
      ],
    },
    {
      id: "order2",
      items: [
        { name: "Item 3", price: 50.0, quantity: 3 },
        { name: "Item 4", price: 300.0, quantity: 1 },
      ],
    },
  ];
  useEffect(() => {
    // Function to handle messages received from the React Native WebView
    const receiveMessageFromReactNative = (event) => {
      // Assuming you are receiving JSON data
      if (
        event.source === window &&
        event.data &&
        typeof event.data === "string"
      ) {
        const data = JSON.parse(event.data);
        console.log("Received data from React Native:", data);
        // Handle the data from your React Native app
        if (data.message) {
          setOneMessage(data.message);
        }
      }
    };

    window.addEventListener("message", receiveMessageFromReactNative);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("message", receiveMessageFromReactNative);
    };
  }, []);
  const initialOptions = {
    clientId:
      "AdQhsG0l6z7W3ss4ZOPfXD8ZauR1t6Qr3-K4S7S3ACQslUGMLzIv1c1HYDvzzSAwpaQhTGu68bxws__h",
    currency: "PHP", // Change the currency to PHP
    intent: "capture",
  };

  const getOrderTotalAmount = (order) => {
    return order.items.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: oneMessage.map((order) => ({
        reference_id: order.id,
        amount: {
          value: getOrderTotalAmount(order).toFixed(2),
          currency_code: "PHP", // Use PHP currency for the purchase unit
          breakdown: {
            item_total: {
              value: getOrderTotalAmount(order).toFixed(2),
              currency_code: "PHP", // Use PHP currency for the item total
            },
          },
        },
        items: order.items.map((item) => ({
          name: item.name,
          unit_amount: {
            value: item.price.toFixed(2),
            currency_code: "PHP", // Use PHP currency for the unit amount
          },
          quantity: item.quantity,
          category: "PHYSICAL_GOODS",
        })),
      })),
    });
  };
  const onApprove = (data, actions) => {
    return actions.order
      .capture()
      .then((details) => {
        // Payment successful, handle the success event here
        console.log("Payment successful:", details);
        const ordered = JSON.stringify(details);

        window.ReactNativeWebView.postMessage(ordered);
      })
      .catch((error) => {
        console.error("Error capturing payment:", error);
      });
  };

  const onError = (error) => {
    console.error("PayPal error:", error);
    // Handle the error gracefully, show an error message to the user, etc.
  };

  return (
    <div className="parentContainer">
      <PayPalScriptProvider options={initialOptions}>
        <div className="paypal-buttons-container">
          <p className="centeredText">
            Please click the button to proceed with the payment.
          </p>
          {/*   {oneMessage && ( */}
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
            style={{ layout: "horizontal" }}
            intent="capture"
          />
          {/*        )} */}
          {/*      <p>{oneMessage}</p> */}
          {/*    <p>eeee</p> */}
          {/*   <button onClick={buttontt}>qqq</button> */}
        </div>
      </PayPalScriptProvider>
    </div>
  );
}

export default App;
